.cfa-text-field-error {
  color: red;
  font-size: 0.6em;
}

.highlight {
  color: red;
}

.non-prod-content {
  /* Non Prod Content */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  gap: 32px;

  position: absolute;
  width: 564px;
  height: 1000px;
  left: 200px;
  top: 0px;
}

.non-prod-header {
  /* 3. Non-Production */

  width: 290px;
  height: 40px;

  /* Headlines/H2 - Bold - 32|40 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.non-prod-greeting {
  /* Congratulations, you’re ready to deploy your apps to a hosted environment. Please enter the redirect_uri for your non-prod app here: */

  width: 564px;
  // height: 72px;

  /* Body Copy/Body 1/Regular */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  // margin: 25px 0px;
  margin-top: 72px;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.non-prod-body {
  /* Congratulations, you’re ready to deploy your apps to a hosted environment. Please enter the redirect_uri for your non-prod app here: */

  width: 564px;
  // height: 72px;

  /* Body Copy/Body 1/Regular */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  margin: 25px 0px;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.non-prod-input-text {
  /* Inputs / Text Fields */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 564px;
  height: 74px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.non-prod-input-button {
  /* Inputs / Buttons */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 12px 24px;
  gap: 10px;

  width: 220px;
  height: 48px;

  margin-top: 20px;

  /* Grayscale/Gray 1 | #ECEDEE */

  background: #ecedee;
  border-radius: 24px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}
