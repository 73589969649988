.cfa-text-field-error {
  color: red;
  font-size: 1em;
}

.centered-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.75rem;
}

.page-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
