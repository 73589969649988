@mixin center {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.75rem;
}

@mixin project-header {
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  color: #5b6770;
}

.cfa-text-field-error {
  color: red;
  font-size: 1em;
}

.centered-container {
  @include center;
}

.redirect-container {
  @include center;
}

.page-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.project-admin-header {
  @include center;
  @include project-header;
}

.highlight {
  color: red;
}
