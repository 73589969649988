.document-header {
  color: #c8102e;
  float: left;
  width: 100%;
}

.home-content {
  /* Home Content */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  gap: 32px;

  position: absolute;
  width: 564px;
  height: 2234px;
  left: 200px;
  top: 0px;
}

.title-frame {
  /* Title Lockup */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 551px;
  height: 72px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.subtitle {
  width: 155px;
  height: 24px;

  /* Overline/Large - Bold - 16|24 */
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-transform: uppercase;

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.title {
  width: 551px;
  height: 40px;

  /* Headlines/H2 - Bold - 32|40 */
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.vendor-status-label {
  width: 564px;
  height: 96px;

  /* Body Copy/Body 1/Regular */
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;
}

.page-text {
  /* Body Copy/Body 1/Regular */
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;
}

.subheader {
  /* Headlines/H3 - Bold - 24|32 */
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;
}

.parachuting-cow-1 {
  /* Restaurant/parachuting-cow */

  position: absolute;
  width: 321px;
  height: 321px;
  left: 844.36px;
  top: 72.28px;

  transform: rotate(15deg);
}

.parachuting-cow-2 {
  /* Restaurant/parachuting-cow */

  position: absolute;
  width: 215.89px;
  height: 215.89px;
  left: 892px;
  top: 741.88px;

  transform: rotate(-15deg);
}
