.cfa-text-field-error {
  color: red;
  font-size: 0.6em;
}

.highlight {
  color: red;
}

.prod-content {
  /* Prod Content */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  gap: 32px;

  position: absolute;
  width: 564px;
  height: 1000px;
  left: 200px;
  top: 0px;
}

.prod-header {
  margin-bottom: 72px;
  /* 4. Production */

  width: 250px;
  height: 40px;

  /* Headlines/H2 - Bold - 32|40 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.prod-body {
  width: 564px;
  height: 168px;

  /* Body Copy/Body 1/Regular */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.prod-form-fields {
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 564px;
  // height: 320px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.prod-text-field {
  /* Input */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 16px;

  position: absolute;
  height: 48px;
  left: -0.5px;
  right: -0.5px;
  top: calc(50% - 48px / 2);

  /* Primary/White */

  background: #ffffff;
  /* Grayscale/Gray 6 | #5B6770 */

  border: 1px solid #5b6770;
  border-radius: 4px;
}

.prod-form-button {
  /* Inputs / Buttons */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;

  width: 102px;
  height: 48px;

  /* Grayscale/Gray 1 | #ECEDEE */

  border-radius: 24px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}
