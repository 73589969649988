@font-face {
  font-family: Apercu-medium;
  src: url('./assets/fonts/Apercu-medium.otf') format('opentype');
}

@font-face {
  font-family: Apercu-bold;
  src: url('./assets/fonts/Apercu-bold.otf') format('opentype');
}

@font-face {
  font-family: Apercu;
  src: url('./assets/fonts/Apercu-regular.otf') format('opentype');
}

@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Regular.ttf') format('opentype');
}

* {
  font-family: Apercu;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: Apercu-bold;
}

button {
  font-family: Apercu-bold !important;
}

body {
  background: #f5f5f5;
}
