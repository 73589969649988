.cfa-text-field-error {
  color: red;
  font-size: 0.4em;
}

.cfa-logo {
  width: 100%;
  height: 5em;
}

.header {
  font-family: 'proxima nova';
}

.box-container {
  margin: auto;
}
