.selectorRow {
  display: flex;
}

.tableRoot {
  margin-top: 25px;
  margin-bottom: 25px;
  overflow-x: inherit !important;
}

.certification-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  gap: 32px;

  position: absolute;
  width: 718px;
  height: 946px;
  left: 200px;
  top: 0px;
}

.certification-headline {
  /* Certification */

  width: 187px;
  height: 40px;

  /* Headlines/H2 - Bold - 32|40 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.certification-vendor {
  /* Frame 90 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 564px;
  height: 96px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.certification-project-name {
  /* Inputs / Text Fields */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 270px;
  height: 96px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.certification-project-description {
  /* Inputs / Text Fields */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 564px;
  height: 188px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.medal {
  /* Misc/medal */

  position: absolute;
  width: 256px;
  height: 256px;
  left: 941px;
  top: 88px;
}

#api-column {
  /* team-member-api */

  width: 311px;
  height: 24px;

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

#scope-column {
  /* team-member-api:read */

  width: 311px;
  height: 24px;

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

#add-delete-column {
  /* Inputs / Buttons */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 10px;

  // width: 130px;
  // height: 48px;

  /* Primary/White */

  // background: #ffffff;
  /* Primary/Chick-fil-A Red */

  // border: 1.5px solid #dd0031;
  // border-radius: 24px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

#api-selector-column {
  /* Inputs / Text Fields */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 270px;
  height: 96px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

#scope-selector-column {
  /* Inputs / Text Fields */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 270px;
  height: 96px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

#selector-row {
  /* Frame 91 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 22px 0px 0px;
  gap: 24px;

  width: 718px;
  height: 118px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
}

#api-name-heading {
  width: 311px;
  height: 16px;

  /* Overline/Small - Bold - 12|16 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

#scope-name-heading {
  width: 311px;
  height: 16px;

  /* Overline/Small - Bold - 12|16 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}
