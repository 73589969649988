.api-catalog-content {
  /* API Catalog Content */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  gap: 32px;

  position: absolute;
  width: 900px;
  height: 1280px;
  left: 200px;
  top: 0px;
}

.catalog-items {
  width: 900px;
  background: white;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.catalog-item-text {
  width: 660px;
  // max-height: 160px;

  /* Body Copy/Body 1/Regular */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 760px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.header {
  /* API Catalog */

  width: 200px;
  height: 40px;

  /* Headlines/H2 - Bold - 32|40 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

// .header {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   padding: 0px;
//   gap: 8px;

//   width: 168px;
//   height: 40px;

//   /* Inside auto layout */

//   flex: none;
//   order: 0;
//   flex-grow: 0;
// }

.collapse-all {
  width: 91px;
  height: 24px;

  /* Button/Button 1 - Bold - 16|24 */

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  /* Primary/Chick-fil-A Red */

  color: #dd0031;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.table-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 718px;
  height: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.table-header-endpoint {
  width: 58px;
  height: 16px;

  /* Overline/Small - Bold - 12|16 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.table-header-scopes {
  width: 51px;
  height: 16px;

  /* Overline/Small - Bold - 12|16 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.table-header-description {
  width: 75px;
  height: 16px;

  /* Overline/Small - Bold - 12|16 */

  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  /* Grayscale/Gray 6 | #5B6770 */

  color: #5b6770;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

code {
  line-height: 40px;
}

.codeBackground {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  max-width: 1200px;
  max-height: 1200px;
  background: #ffffff;
  border-radius: 4px;
}

.codeSnippet {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  max-height: 1200px;
  margin-bottom: 10px;
  color: #5b6770;
}

.codeSnippet > pre {
  white-space: pre-wrap;
}
