.highlight {
  color: red;
}

.table {
  min-width: 48px;
  font-weight: normal;
  vertical-align: top;
  border-right-width: 0;
  border-bottom-width: 0;
  background-clip: padding-box;
  border-spacing: 2px;
}

.table-header {
  background-color: #d8d8d8;
  border-collapse: collapse;
  border: 1px solid;
  table-layout: fixed;
  font-size: 1em;
  width: 100%;
}

.tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0;
  gap: 32px;
  position: absolute;
  width: 665px;
  left: 200px;
  top: 0;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-top: 0;
  /* identical to box height, or 125% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;
}

.first-text-frame {
  width: 564px;
}

p,
li,
span {
  /* Body Copy/Body 1/Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;
}

a {
  /* Body Copy/Body 1/Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.scopes-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 100%;
  border-bottom: 1px solid #cccfd0;
  border-radius: 4px;
}

.scopes-display-text {
  width: 300px;
}

code {
  /* Primary/White */
  background: #ffffff;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  /* or 150% */

  /* Grayscale/Gray 6 | #5B6770 */
  color: #5b6770;
  padding: 15px;
}

code span,
code li,
code a {
  font-family: monospace, monospace;
  line-height: 30px;
}
