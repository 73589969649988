.oauthHeader {
  width: 275px;
  height: 40px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #5b6770;
  margin-top: 0;
}

.oauthBody {
  width: 585px;
  height: 312px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b6770;
}

.oauthSubHeader {
  width: 600px;
  height: 32px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #5b6770;
}

.oauthStepParagraph {
  width: 564px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b6770;
}

.oauthStepParagraph2 {
  width: 564px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b6770;
}

.oauthStepParagraph3 {
  width: 564px;
  height: 48px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b6770;
}

.overviewSectionSubHeader {
  width: 279px;
  height: 32px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #5b6770;
}

.overviewButtonText {
  width: 71px;
  height: 24px;
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.codeBackground {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  max-width: 1200px;
  max-height: 600px;
  background: #ffffff;
  border-radius: 4px;
}

.codeSnippet {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-height: 300px;
  margin-bottom: 10px;
  color: #5b6770;
}

.codeSnippet > pre {
  white-space: pre-wrap;
}

.continueButton {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 119px;
  height: 48px;
  background: #dd0031;
  border-radius: 24px;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  gap: 32px;

  position: absolute;
  width: 800px;
  height: 2144px;
  left: 200px;
  top: 0px;
}
