.documentation {}
.documentation table,
.documentation th,
.documentation td {
  border-collapse: collapse;
  border: 1px solid black;
  table-layout: fixed;
  font-size: 1em;
  padding: 0 0 0 5px;
  height: 0;
  width: 100%;
  margin: 24px 0 0;
}

.documentation tr:first-of-type {
  background-color: #dcdcdd;
  text-align: left;
}

.confluenceTh {
  padding: 0;
}

.documentation pre {
  margin-top: 0px !important;
  background: rgb(255, 255, 255) !important;
}

.documentation code {
  background: rgb(255, 255, 255) !important;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #5b6770;
  padding: 3px;
}

.documentation code span,
.documentation code li,
.documentation code a {
  font-family: monospace, monospace;
  line-height: 30px;
}