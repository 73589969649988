.page-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.tableStyle {
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  margin-top: 0;

  color: #5b6770;
  margin-bottom: 50px;
}

.tableHeader {
  background-color: #d8d8d8;
}

.tableRow {
  margin-top: 50px;
}

.assignedCell {
  margin-left: 50%;
  margin-right: 50%;
  min-width: 200px;
}

.scopeCell {
  min-width: 400px;
  padding-left: 10px;
}

.tableContainer {
  width: 400px;
}

.submitButtonStyle {
  margin-right: 50%;
  margin-left: 50%;
}

.modalProjectName {
  font-size: 20px;
}
