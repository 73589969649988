.mainContent {
  margin-left: 240px;
}

.mainContent h1 {
  text-align: center;
}

Template > div {
  margin: 150px;
}
